import { DateTime } from "luxon";
import { FormArrayState, FormGroupState, updateArray, updateGroup, validate } from "ngrx-forms";

import { validateRequired } from "../../../state/forms-validation";
import { VesselNominationForm } from "../../vessel-nomination-history/vessel-nomination-form";

const validateVesselNominationForm = (form: FormGroupState<VesselNominationForm>, forms: FormArrayState<VesselNominationForm>) =>
    updateGroup<VesselNominationForm>({
        nominationType: validateRequired(),
        nominatedVessel: validateRequired(),
        nominationDate: validate((value) => {
            if (!value) {
                return { valueRequired: true };
            }
            let previous: FormGroupState<VesselNominationForm> | null = null;
            for (const f of forms.controls) {
                if (f.id === form.id) {
                    break;
                }
                previous = f;
            }
            const previousValue = previous?.value.nominationDate;
            if (!!previousValue && DateTime.fromISO(previousValue) > DateTime.fromISO(value)) {
                return { outOfOrder: true };
            }
        }),
        renominationReason: validateRequired()
    })(form);

export const validateVesselNominationForms = (forms: FormArrayState<VesselNominationForm>) => updateArray<VesselNominationForm>(validateVesselNominationForm)(forms);
