import { Boxed, unbox } from "ngrx-forms";

import { Nullable } from "../../shared/models/types";
import { VesselNominationType } from "../../shared/reference-data/vessel-nomination-type";
import { VesselRenominationReason } from "../../shared/reference-data/vessel-renomination-reason";
import { Vessel } from "../shared/models";

export type VesselNominationForm = Readonly<{
    nominationType: Boxed<Nullable<VesselNominationType>>;
    nominationDate: string;
    nominatedVessel: Boxed<Nullable<Vessel>>;
    renominationReason: Boxed<Nullable<VesselRenominationReason>>;
}>;

export const toVesselNomination = (form: VesselNominationForm) => ({
    nominationType: unbox(form.nominationType),
    nominationDate: form.nominationDate,
    vessel: unbox(form.nominatedVessel),
    renominationReason: unbox(form.renominationReason)
});
