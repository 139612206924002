import { createAction, on, On, props } from "@ngrx/store";
import { box, createFormArrayState } from "ngrx-forms";

import { VesselNominationType } from "../../../shared/reference-data/vessel-nomination-type";
import { VesselRenominationReason } from "../../../shared/reference-data/vessel-renomination-reason";
import { Fixture } from "../../shared/models";
import { VesselNomination } from "../../shared/models/dtos/vessel-nomination.dto";
import { VesselNominationForm } from "../../vessel-nomination-history/vessel-nomination-form";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Vessel Nomination History] Init";

export const initVesselNominationHistoryAction = createAction(ACTION_NAME, props<{ appendCurrentVessel: boolean }>());

/* REDUCERS */
export const initVesselNominationHistoryReducer: On<FixturesState> = on(initVesselNominationHistoryAction, (state, { appendCurrentVessel }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        vesselNominationForms: createVesselNominationFormsState(fixtureState.workingFixture, appendCurrentVessel)
    }))
);

export const createVesselNominationFormsState = (fixture: Fixture, appendCurrentVessel: boolean) => {
    const forms: VesselNominationForm[] = [];
    const hasHistory = !!fixture.vesselNominationHistory?.length;
    if (hasHistory) {
        forms.push(...toVesselNominationForms(fixture.vesselNominationHistory));
    }
    if (appendCurrentVessel && fixture.vessel) {
        forms.push({
            nominationType: box(hasHistory ? null : VesselNominationType.Initial),
            nominationDate: hasHistory ? "" : fixture.vesselNominationDate ?? "",
            nominatedVessel: box(fixture.vessel),
            renominationReason: box(hasHistory ? null : VesselRenominationReason.NotApplicable)
        });
    }
    return createFormArrayState(`${fixture.fixtureId}.vesselNominationForms`, forms);
};

const toVesselNominationForms = (vesselNominationHistory: ReadonlyArray<VesselNomination>) =>
    vesselNominationHistory?.map((item) => ({
        nominationType: box(item.nominationType),
        nominationDate: item.nominationDate ?? "",
        nominatedVessel: box(item.vessel),
        renominationReason: box(item.renominationReason)
    }));
