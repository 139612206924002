import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { debounceTime, map, take } from "rxjs/operators";

import { RevertVesselCommand } from "./commands/revert-vessel-command";
import { SaveVesselNominationHistoryCommand } from "./commands/save-vessel-nomination-history.command";
import { UpdateVoyageHeadingCommand } from "./commands/update-voyage-heading.command";
import { VesselNameValidator } from "../../../left-bar/create-fixture/validators/vessel-name-validator";
import { CancellationReason, LaycanExtensionReason } from "../../../shared/reference-data";
import { Enumeration } from "../../../shared/reference-data/enumeration";
import { FreightType } from "../../../shared/reference-data/freight-type";
import { ReferenceDataService } from "../../../shared/reference-data/reference-data.service";
import { parseISODateAsUtc } from "../../../shared/utils/date-utils";
import { HeaderVoyageValidator } from "../../../shared/validators/component-validators/header-component/header-voyage-validator";
import { RangeValidator } from "../../../shared/validators/range.validator";
import { RequiredIfValidator } from "../../../shared/validators/required-if.validator";
import { Command } from "../../mediator";
import { FixtureDataService } from "../../services/fixture-data.service";
import { FormComponentBase } from "../../shared/form-component-base";
import { Division, Fixture, User, VoyageHeadingFormModel } from "../../shared/models";
import { VesselData } from "../../shared/models/common/vessel-data";
import { Currency } from "../../shared/models/dtos/currency.dto";
import { FixtureSource } from "../../shared/models/enums/fixture-source";
import { FixtureWarningPathMapper } from "../../shared/warnings/fixture-warning-path-mapper";
import { selectCurrentFixtureVesselNominationForms } from "../../state";
import { toVesselNomination } from "../../vessel-nomination-history/vessel-nomination-form";
import { VesselNominationHistoryPopupComponent } from "../../vessel-nomination-history/vessel-nomination-history-popup.component";

@Component({
    selector: "ops-voyage-heading",
    templateUrl: "./voyage-heading.component.html",
    styleUrls: ["./voyage-heading.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoyageHeadingComponent extends FormComponentBase implements OnInit, OnDestroy, OnChanges {
    static componentName = "VoyageHeadingComponent";

    private cpmLinkRegEx = String.raw`https://cpmanager\.cloud/.+|https://contracts\.sea\.live/.+|https://www\.recapmanager\.co\.uk/.+`;
    private patchedValues: VoyageHeadingFormModel;

    readonly tabName = "voyageHeader";

    FreightType = FreightType;

    Division = Division;
    FixtureSource = FixtureSource;
    fixtureStatuses$: Observable<Enumeration[]>;
    demurrageStatuses$: Observable<Enumeration[]>;
    freightTypes$: Observable<Enumeration[]>;
    laycanExtensionReasons$: Observable<LaycanExtensionReason[]>;
    cancellationReasons$: Observable<CancellationReason[]>;
    cpTypes$: Observable<Enumeration[]>;
    leadOperator: User;
    headingForm: UntypedFormGroup;
    operatorCount: number;
    lastCargoCount: number;
    suggestedOperators$: Observable<User[]>;
    loiStatuses$: Observable<Enumeration[]>;
    currencies$: Observable<Currency[]>;
    presentationTypes$: Observable<Enumeration[]>;

    @Input() fixture: Fixture;
    @Input() parentForm: UntypedFormGroup;
    @Output() headingUpdated = new EventEmitter<Command>();

    get isLumpSumValueRequired() {
        return this.headingForm.value?.freightType?.id === FreightType.LumpSum.id;
    }

    get isVesselNominationHistoryLinkEnabled() {
        return !this.parentForm.disabled && !!this.fixture.vesselNominationHistory?.length;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        public referenceDataService: ReferenceDataService,
        public fixtureDataService: FixtureDataService,
        private warningPathMapper: FixtureWarningPathMapper,
        private dialog: MatDialog,
        protected store: Store
    ) {
        super(store);
    }

    ngOnInit(): void {
        this.fixtureStatuses$ = this.referenceDataService.getFixtureStatuses();
        this.demurrageStatuses$ = this.referenceDataService
            .getDemurrageStatuses()
            .pipe(map((data: Enumeration[]) => data.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))));
        this.freightTypes$ = this.referenceDataService.getFreightTypes();
        this.laycanExtensionReasons$ = this.referenceDataService.getLaycanExtensionReasons();
        this.cancellationReasons$ = this.referenceDataService.getCancellationReasons();
        this.cpTypes$ = this.referenceDataService.getCpTypes().pipe(
            map((data: Enumeration[]) => {
                const { division } = this.fixtureDataService;
                if (division === Division.tankers || division === Division.gas) {
                    return data.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
                }

                return data;
            })
        );
        this.loiStatuses$ = this.referenceDataService.getLoiStatuses();
        this.currencies$ = this.referenceDataService.getCurrencies();
        this.presentationTypes$ = this.referenceDataService.getPresentationTypes();

        this.createForm();
        this.parentForm.registerControl("heading", this.headingForm);
        this.setForm(this.fixture);
        this.subscribeToFormValueChanges();

        this.warningPathMapper.registerPathTransform((path) => (path[0] === "demurrageStatus" ? ["demurrage", "status"] : path));

        this.subscribeToFormStatusChanges([this.headingForm], this.tabName);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fixture.firstChange) {
            return;
        }

        this.removeFormSubscriptions();
        this.setForm(changes.fixture.currentValue);
        this.subscribeToFormValueChanges();
    }

    ngOnDestroy(): void {
        this.removeStatusChangesSubscrition();
        this.removeFormSubscriptions();
        delete this.parentForm.controls.heading;
    }

    removeIsVesselTbn(): void {
        const vessel = this.headingForm.controls["vessel"].value;
        if (vessel) {
            this.headingForm.patchValue({ isVesselTbn: false });
        }
    }

    removeVessel(): void {
        const isVesselTbn = this.headingForm.controls["isVesselTbn"].value;
        if (isVesselTbn) {
            this.headingForm.patchValue({ vessel: null });
        }
    }

    openVesselNominationHistoryDialog(data: boolean) {
        this.dialog
            .open(VesselNominationHistoryPopupComponent, { data, disableClose: true })
            .afterClosed()
            .subscribe((result) => {
                if (result === "ok") {
                    this.store
                        .select(selectCurrentFixtureVesselNominationForms)
                        .pipe(take(1))
                        .subscribe((forms) => {
                            const vesselNominations = forms?.controls.map((form) => toVesselNomination(form.value));
                            this.headingUpdated.emit(new SaveVesselNominationHistoryCommand(vesselNominations));
                        });
                } else {
                    this.headingUpdated.emit(new RevertVesselCommand());
                }
            });
    }

    protected subscribeToFormValueChanges() {
        this.formValueSubscription = this.headingForm.valueChanges.pipe(debounceTime(10)).subscribe((value) => {
            this.headingUpdated.emit(new UpdateVoyageHeadingCommand({ ...this.patchedValues, ...value }));

            if (!this.patchedValues.vessel && value.vessel) {
                this.openVesselNominationHistoryDialog(true);
            }
        });
    }

    private createForm() {
        this.headingForm = this.formBuilder.group(
            {
                operators: [],
                claims: [],
                charterPartyType: [],
                cpmLink: ["", Validators.pattern(this.cpmLinkRegEx)],
                voyageReference: [],
                chartererReference: [],
                ownerReference: [],
                liftingNumber: [],
                liftingDate: [],
                vesselNominationDate: [],
                fixedLaytime: [],
                fixtureStatus: [],
                cancellationReason: ["", { updateOn: "change" }],
                laycanFrom: ["", Validators.required],
                laycanTo: ["", Validators.required],
                laycanExtFrom: [],
                laycanExtTo: [],
                laycanExtensionReason: [],
                comments: [],
                isCommentsImportant: [false, { updateOn: "change" }],
                demurrageStatus: [null, Validators.required],
                demurrageRate: [],
                despatchRate: [],
                lastCargoes: [],
                loiStatus: [],
                loiStatusNotes: [],
                speedLaden: [],
                displayBroker: [],
                isVesselTbn: [false, { updateOn: "change" }],
                chartererReference2: [],
                vessel: ["", { updateOn: "change" }],
                cpDate: ["", Validators.required],
                currency: ["", Validators.required],
                coANumber: [],
                freightType: [null, Validators.required],
                lumpsumValue: [],
                brokerCommission: [],
                addressCommission: [],
                presentationType: ["", { updateOn: "change" }],
                includePresentationInKpi: [false],
                includeCancelledInKpi: [false],
                numberOfVesselChanges: []
            },
            {
                validators: Validators.compose([
                    RequiredIfValidator.validate("laycanExtFrom", "laycanExtTo"),
                    VesselNameValidator.validate(),
                    RangeValidator.validate("laycanFrom", "laycanTo"),
                    RangeValidator.validate("laycanExtFrom", "laycanExtTo"),
                    HeaderVoyageValidator.validate()
                ]),
                updateOn: "blur"
            }
        );

        if (this.parentForm.disabled) {
            this.headingForm.disable();
        } else {
            this.headingForm.enable();
        }
    }

    private setForm(fixture: Fixture) {
        let operators: User[] = [];

        if (fixture.operators && fixture.operators.length) {
            operators = [fixture.leadOperator, ...fixture.operators.filter((operator) => operator.userId !== fixture.leadOperator.userId)];
        }

        this.leadOperator = fixture.leadOperator;

        const dataModel: VoyageHeadingFormModel = {
            operators,
            claims: fixture.claims,
            charterPartyType: fixture.charterParty.charterPartyType,
            cpmLink: fixture.charterParty.cpmLink,
            voyageReference: fixture.voyageReference,
            ownerReference: fixture.ownerReference,
            chartererReference: fixture.chartererReference,
            liftingNumber: fixture.liftingNumber,
            liftingDate: parseISODateAsUtc(fixture.liftingDate),
            vesselNominationDate: parseISODateAsUtc(fixture.vesselNominationDate ? fixture.vesselNominationDate : null),
            fixedLaytime: fixture.fixedLaytime,
            fixtureStatus: fixture.fixtureStatus,
            cancellationReason: fixture.cancellationReason,
            laycanFrom: parseISODateAsUtc(fixture.laycan?.date?.from),
            laycanTo: parseISODateAsUtc(fixture.laycan?.date?.to),
            laycanExtFrom: parseISODateAsUtc(fixture.laycan?.extensionDate?.from),
            laycanExtTo: parseISODateAsUtc(fixture.laycan?.extensionDate?.to),
            laycanExtensionReason: fixture.laycan?.extensionReason,
            comments: fixture.comments,
            isCommentsImportant: fixture.isCommentsImportant,
            demurrageStatus: fixture.demurrage.status,
            demurrageRate: fixture.demurrage.rate,
            despatchRate: fixture.despatchRate,
            lastCargoes: fixture.lastCargoes,
            loiStatus: fixture.loiStatus,
            loiStatusNotes: fixture.loiStatusNotes,
            speedLaden: fixture.speedLaden,
            vessel: !fixture.vessel ? null : <VesselData>{ name: fixture.vessel.name, imoNumber: fixture.vessel.imo, cvn: fixture.vessel.cvn },
            displayBroker: fixture.displayBroker,
            cpDate: parseISODateAsUtc(fixture.charterParty.charterPartyDate),
            isVesselTbn: fixture.isVesselTbn,
            currency: fixture.currency,
            chartererReference2: fixture.chartererReference2,
            coANumber: fixture.coANumber,
            freightType: fixture.freightType,
            lumpsumValue: fixture.lumpsumValue,
            brokerCommission: fixture.brokerCommission,
            addressCommission: fixture.addressCommission,
            presentationType: fixture.presentationType,
            includePresentationInKpi: fixture.includePresentationInKpi,
            includeCancelledInKpi: fixture.includeCancelledInKpi,
            numberOfVesselChanges: fixture.numberOfVesselChanges
        };

        this.operatorCount = fixture.operators ? fixture.operators.length : 0;
        this.lastCargoCount = fixture.lastCargoes ? fixture.lastCargoes.length : 0;

        if (this.parentForm.disabled) {
            this.headingForm.disable();
        } else {
            this.headingForm.enable();
        }

        if (this.fixture.fixtureSource.id === FixtureSource.Gain) {
            this.headingForm.controls.cpDate.disable();
            this.headingForm.controls.currency.disable();
        }

        if (this.fixture.freightType && this.fixture.freightType.id !== FreightType.LumpSum.id) {
            this.headingForm.get("lumpsumValue").disable();
        }

        if (!this.fixture.presentationType) {
            this.headingForm.get("includePresentationInKpi").disable();
            dataModel.includePresentationInKpi = false;
        }

        if (!this.fixture.cancellationReason) {
            this.headingForm.get("includeCancelledInKpi").disable();
            dataModel.includeCancelledInKpi = false;
        }

        this.headingForm.patchValue(dataModel, { emitEvent: false });
        this.patchedValues = dataModel;
    }
}
