import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Store } from "@ngrx/store";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";

import { AppInsightsService, CoreModule, AuthService, FeatureToggleService, HotjarService } from "@ops/core";
import { SharedModule } from "@ops/shared";
import { AppState, interopUserAuthenticated } from "@ops/state";

import { ActionModule, LiveUpdatesHubService } from "./action";
import { AppRoutingModule } from "./app-routing.module";
import { AppStoreModule } from "./app-store.module";
import { AppComponent } from "./app.component";
import { CustomErrorHandler } from "./core/custom-error-handler";
import { OpsRouteReuseStrategy } from "./core/ops-route-reuse-strategy";
import { FixtureModule } from "./fixture/fixture.module";
import { FixturePopupDialogService } from "./fixture/popup/fixture-popup-dialog.service";
import { FixturePopupComponent } from "./fixture/popup/fixture-popup.component";
import { VesselNominationHistoryPopupComponent } from "./fixture/vessel-nomination-history/vessel-nomination-history-popup.component";
import { LeftBarModule } from "./left-bar/left-bar.module";
import { NavbarComponent } from "./navbar/navbar.component";
import { VersionService } from "./shared/version/version.service";
import { envModules } from "../environments/environment";

const initApp =
    (
        authService: AuthService,
        appInsights: AppInsightsService,
        hotjarService: HotjarService,
        liveUpdatesHubService: LiveUpdatesHubService,
        store: Store<AppState>,
        versionService: VersionService,
        featureToggleService: FeatureToggleService,
        fixturePopupDialogService: FixturePopupDialogService
    ) =>
    () => {
        try {
            appInsights.initialize();
        } catch (err) {
            console.error("Unable to initialize Application Insights", err);
        }

        try {
            hotjarService.initialize();
        } catch (err) {
            console.error("Unable to initialize Hotjar", err);
        }

        try {
            fixturePopupDialogService.initialize();
        } catch (err) {
            console.error("Unable to initialize fixturePopupDialogService", err);
        }

        return featureToggleService
            .initialize()
            .then(() => authService.setupAuthentication())
            .then(() => {
                try {
                    appInsights.setUserContext(authService.user);
                    liveUpdatesHubService.initialize();
                    versionService.startPolling();
                } finally {
                    if (authService.user) {
                        store.dispatch(interopUserAuthenticated({ user: authService.user }));
                    }
                }
            });
    };

@NgModule({
    declarations: [AppComponent, NavbarComponent, FixturePopupComponent, VesselNominationHistoryPopupComponent],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        AppStoreModule,
        AppRoutingModule,
        LeftBarModule,
        SharedModule,
        FixtureModule,
        ActionModule,
        TabViewModule,
        TabMenuModule,
        envModules
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AuthService, AppInsightsService, HotjarService, LiveUpdatesHubService, Store, VersionService, FeatureToggleService, FixturePopupDialogService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        { provide: RouteReuseStrategy, useClass: OpsRouteReuseStrategy },
        { provide: "Window", useValue: window },
        { provide: LOCALE_ID, useValue: "en" },
        { provide: HubConnectionBuilder, useFactory: () => new HubConnectionBuilder() }
    ]
})
export class AppModule {}
