<div *ngIf="vesselNominationForms">
    <p-table [value]="vesselNominationForms.controls" [formGridReadonly]="false" dataKey="id" [rowTrackBy]="trackBy"
        tableStyleClass="ops-form-grid ops-vessel-nomination-history-table">
        <ng-template pTemplate="header">
            <tr>
                <th>Nomination Type *</th>
                <th>Nominated Vessel *</th>
                <th>Nomination Date *</th>
                <th>Renomination Reason *</th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr class="p-grid" formGridRow [attr.rowkey]="rowData.id">
                <td *ngIf="!rowIndex">
                    <span class="ops-fg-cell-value">{{rowData.value.nominationType.value.name}}</span>
                </td>
                <td *ngIf="rowIndex" pEditableColumn [formGridCell]="rowData.controls.nominationType">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select #nominationTypeDropdown formGridInput class="ops-select"
                                [ngrxFormControlState]="rowData.controls.nominationType"
                                [ngbPopover]="nominationTypeValidation" bindLabel="name"
                                [items]="vesselNominationTypes$ | async" [clearable]="true" [selectOnTab]="true"
                                (open)="adjustDropdownPosition(nominationTypeDropdown, true)" placeholder="Select">
                            </ng-select>
                            <ng-template #nominationTypeValidation>
                                <ops-field-validation
                                    *ngIf="rowData.controls.nominationType.errors.required && rowData.controls.nominationType.isTouched"
                                    fieldDisplayName="Nomination Type" errorMessage="is required">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.value.nominationType?.value?.name}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td pEditableColumn [formGridCell]="rowData.controls.nominatedVessel">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-vessel-autosuggest [multiple]="false"
                                [selection]="rowData.value.nominatedVessel?.value" class="vessel-name"
                                [ngbPopover]="nominatedVesselValidation"
                                [ngrxFormControlState]="rowData.controls.nominatedVessel"
                                (open)="adjustDropdownPosition($event, false)"></ops-vessel-autosuggest>
                            <ng-template #nominatedVesselValidation>
                                <ops-field-validation
                                    *ngIf="rowData.controls.nominatedVessel.errors.required && rowData.controls.nominatedVessel.isTouched"
                                    fieldDisplayName="Nominated Vessel" errorMessage="is required">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.value.nominatedVessel?.value?.name}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td pEditableColumn [formGridCell]="rowData.controls.nominationDate">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-date-input [enableTime]="false" tabbingBehaviour="time"
                                [ngrxFormControlState]="rowData.controls.nominationDate"
                                [ngbPopover]="nominationDateValidation"
                                [ngrxValueConverter]="dateValueConverter" timeZone="utc" formGridInput>
                            </ops-date-input>
                            <ng-template #nominationDateValidation>
                                <ops-field-validation
                                    *ngIf="rowData.controls.nominationDate.errors.valueRequired && rowData.controls.nominationDate.isTouched"
                                    fieldDisplayName="Nomination Date" errorMessage="is required">
                                </ops-field-validation>
                                <ops-field-validation class="nomination-date-out-of-order-validation-message"
                                    *ngIf="rowData.controls.nominationDate.errors.outOfOrder"
                                    fieldDisplayName="Nomination Date" errorMessage="should be later than the previous nomination">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">
                                {{rowData.value.nominationDate | maritimeDate:false:"utc"}}
                            </span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td *ngIf="!rowIndex">
                    <span class="ops-fg-cell-value">{{rowData.value.renominationReason.value.name}}</span>
                </td>
                <td *ngIf="rowIndex" pEditableColumn [formGridCell]="rowData.controls.renominationReason">
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select #renominationReasonDropdown formGridInput class="ops-select"
                                [ngrxFormControlState]="rowData.controls.renominationReason"
                                [ngbPopover]="renominationReasonValidation" bindLabel="name"
                                [items]="vesselRenominationReasons$ | async" [clearable]="true" [selectOnTab]="true"
                                (open)="adjustDropdownPosition(renominationReasonDropdown, true)" placeholder="Select">
                            </ng-select>
                            <ng-template #renominationReasonValidation>
                                <ops-field-validation
                                    *ngIf="rowData.controls.renominationReason.errors.required && rowData.controls.renominationReason.isTouched"
                                    fieldDisplayName="Renomination Reason" errorMessage="is required">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.value.renominationReason?.value?.name}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>

                <td class="ops-fg-action-cell">
                    <ops-icon-button type="button" icon="add-circle-outline"
                        (click)="addRow.emit(rowIndex + 1)"></ops-icon-button>
                </td>

                <td class="ops-fg-action-cell">
                    <ops-icon-button *ngIf="rowIndex > 0" type="delete"
                        (click)="removeRow.emit(rowIndex)"></ops-icon-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>