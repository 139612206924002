import { Injectable } from "@angular/core";

import { RevertVesselCommand } from "./revert-vessel-command";
import { CommandHandler } from "../../../mediator";
import { Fixture } from "../../../shared/models";

@Injectable({
    providedIn: "root"
})
export class RevertVesselCommandHandler implements CommandHandler {
    handle(fixture: Fixture, _: RevertVesselCommand) {
        if (fixture.vessel) {
            const vesselNominations = fixture.vesselNominationHistory;
            fixture.vessel = vesselNominations[vesselNominations.length - 1].vessel;
        }
        fixture.isVesselTbn = !fixture.vessel;
    }
}
