import { Injectable } from "@angular/core";

import { SaveVesselNominationHistoryCommand } from "./save-vessel-nomination-history.command";
import { CommandHandler } from "../../../mediator";
import { Fixture } from "../../../shared/models";
import { VesselNomination } from "../../../shared/models/dtos/vessel-nomination.dto";

@Injectable({
    providedIn: "root"
})
export class SaveVesselNominationHistoryCommandHandler implements CommandHandler {
    handle(fixture: Fixture, command: SaveVesselNominationHistoryCommand) {
        const vesselNominations = command.payload as VesselNomination[];
        fixture.vesselNominationHistory = vesselNominations;
        fixture.vessel = vesselNominations[vesselNominations.length - 1].vessel;
        fixture.vesselNominationDate = vesselNominations[vesselNominations.length - 1].nominationDate;
        fixture.isVesselTbn = false;
    }
}
