import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { FormArrayState, FormState } from "ngrx-forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { VesselNominationForm } from "./vessel-nomination-form";
import { Enumeration, ReferenceDataService } from "../../shared/reference-data";
import { VesselNominationType } from "../../shared/reference-data/vessel-nomination-type";
import { VesselRenominationReason } from "../../shared/reference-data/vessel-renomination-reason";
import { CustomNgrxValueConverters } from "../../state";

@Component({
    selector: "ops-vessel-nomination-history-table",
    templateUrl: "./vessel-nomination-history-table.component.html",
    styleUrls: ["./vessel-nomination-history-table.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VesselNominationHistoryTableComponent implements OnInit {
    readonly dateValueConverter = CustomNgrxValueConverters.dateToLocalDateTimeString;

    vesselNominationTypes$: Observable<string[]>;
    vesselRenominationReasons$: Observable<string[]>;

    @Input() vesselNominationForms: FormArrayState<VesselNominationForm>;
    @Output() readonly addRow = new EventEmitter<{ index: number }>();
    @Output() readonly removeRow = new EventEmitter<{ index: number }>();

    constructor(private referenceDataService: ReferenceDataService) {}

    ngOnInit(): void {
        this.vesselNominationTypes$ = this.referenceDataService
            .getVesselNominationTypes()
            .pipe(map((array: Enumeration[]) => array.filter((item) => item.id !== VesselNominationType.Initial.id)));
        this.vesselRenominationReasons$ = this.referenceDataService
            .getVesselRenominationReasons()
            .pipe(map((array: Enumeration[]) => array.filter((item) => item.id !== VesselRenominationReason.NotApplicable.id)));
    }

    trackBy(item: FormState<VesselNominationForm>) {
        return item.id;
    }

    adjustDropdownPosition(ngSelect: NgSelectComponent, calculateHeight: boolean) {
        const parentEl = ngSelect.element.closest(".vessel-nomination-history-popup-container");
        if (!parentEl) {
            throw new Error("Can't find .vessel-nomination-history-popup-container element");
        }
        const ngSelectRect = ngSelect.element.getBoundingClientRect();
        const parentElRect = parentEl.getBoundingClientRect();
        const maxDropdownHeight = 240;
        const minDropdownHeight = 200;
        const itemHeight = 33;
        // for Cargo dropdown we can calculate dropdown height, for the rest we take fixed value
        const dropdownHeight = calculateHeight ? (ngSelect.items?.length ?? 1) * itemHeight + 1 : minDropdownHeight;
        // if we can't open the dropdown downwards, open it upwards
        if (ngSelectRect.bottom + dropdownHeight >= parentElRect.bottom) {
            ngSelect.dropdownPosition = "top";
            // if there is no enough space upwards, shrink the dropdown
            if (ngSelectRect.top - maxDropdownHeight <= parentElRect.top) {
                this.adjustDropdownPanelHeight(parentEl, ngSelectRect.top - parentElRect.top - 1);
            }
        } else {
            ngSelect.dropdownPosition = "bottom";
            // if there is no enough space downwards, shrink the dropdown
            if (ngSelectRect.bottom + maxDropdownHeight >= parentElRect.bottom) {
                this.adjustDropdownPanelHeight(parentEl, parentElRect.bottom - ngSelectRect.bottom - 1);
            }
        }
    }

    // adjusts the max height for the dropdown panel
    private adjustDropdownPanelHeight(parentEl: Element, maxHeight: number) {
        setTimeout(() => {
            const dropdownEl = <HTMLDivElement>parentEl.querySelector(".ng-dropdown-panel-items");
            if (dropdownEl) {
                dropdownEl.style.maxHeight = `${maxHeight}px`;
            }
        });
    }
}
