import { createAction, props, on, On } from "@ngrx/store";
import { addArrayControl, box } from "ngrx-forms";

import { VesselNominationForm } from "../../vessel-nomination-history/vessel-nomination-form";
import { currentFixtureStateReducer } from "../fixture/reducer";
import { FixturesState } from "../model/state";

/* ACTIONS */
const ACTION_NAME = "[Vessel Nomination History] Add Row";

export const addVesselNominationHistoryRowAction = createAction(ACTION_NAME, props<{ rowIndex: number }>());

/* REDUCERS */
export const addVesselNominationHistoryRowReducer: On<FixturesState> = on(addVesselNominationHistoryRowAction, (state, { rowIndex }) =>
    currentFixtureStateReducer(state, (fixtureState) => ({
        ...fixtureState,
        vesselNominationForms: addArrayControl(fixtureState.vesselNominationForms, createEmptyRow(), rowIndex)
    }))
);

const createEmptyRow = (): VesselNominationForm => ({
    nominationType: box(null),
    nominationDate: "",
    nominatedVessel: box(null),
    renominationReason: box(null)
});
