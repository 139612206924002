import { ChangeDetectionStrategy, Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { FormArrayState } from "ngrx-forms";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";

import { VesselNominationForm } from "./vessel-nomination-form";
import { FixtureFeatureState, selectCurrentFixtureVesselNominationForms } from "../state";
import { addVesselNominationHistoryRowAction } from "../state/vessel-nomination-history/add-vessel-nomination-history-row";
import { initVesselNominationHistoryAction } from "../state/vessel-nomination-history/init-vessel-nomination-history";
import { removeVesselNominationHistoryRowAction } from "../state/vessel-nomination-history/remove-vessel-nomination-history-row";

@Component({
    selector: "ops-vessel-nomination-history-popup",
    templateUrl: "./vessel-nomination-history-popup.component.html",
    styleUrls: ["./vessel-nomination-history-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VesselNominationHistoryPopupComponent implements OnInit {
    private appendCurrentVessel: boolean;

    vesselNominationForms$: Observable<FormArrayState<VesselNominationForm>>;

    constructor(@Inject(MAT_DIALOG_DATA) data: boolean, private dialogRef: MatDialogRef<VesselNominationHistoryPopupComponent>, private store: Store<FixtureFeatureState>) {
        this.appendCurrentVessel = data;
        this.vesselNominationForms$ = store.select(selectCurrentFixtureVesselNominationForms);
    }

    ngOnInit(): void {
        this.store.dispatch(initVesselNominationHistoryAction({ appendCurrentVessel: this.appendCurrentVessel }));
    }

    addRow(rowIndex: number) {
        this.store.dispatch(addVesselNominationHistoryRowAction({ rowIndex }));
    }

    removeRow(rowIndex: number) {
        this.store.dispatch(removeVesselNominationHistoryRowAction({ rowIndex }));
    }

    cancel() {
        this.dialogRef.close("cancel");
    }

    apply() {
        this.vesselNominationForms$
            .pipe(
                take(1),
                filter((forms) => forms.controls.every((f) => f.isValid))
            )
            .subscribe(() => {
                this.dialogRef.close("ok");
            });
    }
}
