import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { NgbModalModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgrxFormsModule } from "ngrx-forms";
import { NgxFileDropModule } from "ngx-file-drop";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { ToggleButtonModule } from "primeng/togglebutton";

import { VoyageShellComponent } from "./components";
import { ActivitiesComponent, ActivityFormComponent, ActivityHeaderComponent, ActivityShellComponent } from "./components/activities";
import { AssociatedCargoesComponent, AssociatedCargoExpensesComponent } from "./components/associated-cargoes";
import { BerthFormComponent, BerthHeaderComponent, BerthsComponent, BerthShellComponent } from "./components/berths";
import { CargoesComponent, CargoFormComponent, SpecialisedCargoFormComponent, SpecialisedCargoShellComponent } from "./components/cargoes";
import { DestinationFormComponent, DestinationHeaderComponent, DestinationsComponent, DestinationsHeaderComponent, DestinationShellComponent } from "./components/destinations";
import { LaytimeEventsComponent } from "./components/laytime-events";
import { ProfitAndLossComponent } from "./components/profit-and-loss/profit-and-loss.component";
import { ProfitAndLossShellComponent } from "./components/profit-and-loss-shell.component";
import { PublicNotesFormComponent } from "./components/public-notes/public-notes-form.component";
import { SectionHeaderComponent } from "./components/shared/section-header/section-header.component";
import { TotalsFormComponent } from "./components/totals/totals-form.component";
import { TimeCharterVoyageFormComponent } from "./components/voyage";
import { VoyageCostBreakdownComponent } from "./components/voyage-cost-breakdown/voyage-cost-breakdown.component";
import { ClaimsNotificationComponent } from "./demurrage-tab/claims-notification/claims-notification.component";
import { DemurrageClaimSummaryComponent } from "./demurrage-tab/demurrage-claim/demurrage-claim-summary/demurrage-claim-summary.component";
import { DemurrageClaimComponent } from "./demurrage-tab/demurrage-claim/demurrage-claim.component";
import { DemurrageClaimContainerComponent } from "./demurrage-tab/demurrage-claim-container/demurrage-claim-container.component";
import { DemurrageTabComponent } from "./demurrage-tab/demurrage-tab.component";
import { TimebarsComponent } from "./demurrage-tab/timebars/timebars.component";
import { ExpenseComponent } from "./expense-tab/expense/expense.component";
import { ExpenseSummaryComponent } from "./expense-tab/expense-summary/expense-summary.component";
import { ExpenseTabComponent } from "./expense-tab/expense-tab.component";
import { FixtureRoutingModule } from "./fixture-routing.module";
import { FixtureComponent } from "./fixture.component";
import { CargoAllowedRatesComponent } from "./laytime-tab/cargo-allowed-rates/cargo-allowed-rates.component";
import { LaytimeCalculationComponent } from "./laytime-tab/laytime-calculation/laytime-calculation.component";
import { LaytimeContainerComponent } from "./laytime-tab/laytime-container/laytime-container.component";
import { LaytimeSummaryComponent } from "./laytime-tab/laytime-summary/laytime-summary.component";
import { LaytimeTabComponent } from "./laytime-tab/laytime-tab.component";
import { AssociatedCargoesStatementOfFactsComponent } from "./laytime-tab/statement-of-facts-container/associated-cargoes-statement-of-facts/associated-cargoes-statement-of-facts.component";
import { BerthStatementOfFactsComponent } from "./laytime-tab/statement-of-facts-container/berth-statement-of-facts/berth-statement-of-facts.component";
import { LaytimeEventFactsComponent } from "./laytime-tab/statement-of-facts-container/berth-statement-of-facts/laytime-event-facts/laytime-event-facts.component";
import { StatementOfFactsContainerComponent } from "./laytime-tab/statement-of-facts-container/statement-of-facts-container.component";
import {
    FixturePopupCommentsComponent,
    FixturePopupFixtureInfoComponent,
    FixturePopupLaytimeEventsComponent,
    FixturePopupBodyComponent,
    FixturePopupFooterComponent,
    FixturePopupHeaderComponent,
    FixturePopupTabHeaderComponent,
    FixturePopupDestinationTabHeaderComponent,
    FixturePopupDestinationTabHeaderFormComponent,
    FixturePopupActivityTabHeaderComponent,
    FixturePopupActivityTabHeaderFormComponent
} from "./popup";
import { FIXTURE_POPUP_TAB_CREATOR, FixturePopupCommentsTabCreator, FixturePopupActivitiesTabCreator, FixturePopupFixtureInfoTabCreator } from "./popup/tab-creators";
import { VoyageDataService } from "./services/voyage-data.service";
import { CommissionComponent } from "./shared/commission/commission.component";
import { CargoAutosuggestComponent } from "./shared/components/autosuggest/cargo/cargo-autosuggest.component";
import { LocationAutosuggestComponent } from "./shared/components/autosuggest/location/location-autosuggest.component";
import { DivisionsDirective } from "./shared/directives/divisions.directive";
import { FixtureSourceDirective } from "./shared/directives/fixture-source.directive";
import { FixtureTypeDirective } from "./shared/directives/fixture-type.directive";
import { InvoiceSummaryComponent } from "./shared/invoices/invoice-summary/invoice-summary.component";
import { FixtureWarningsModule } from "./shared/warnings/fixture-warnings.module";
import { BunkerConsumptionComponent } from "./speed-and-consumption-tab/cp-speed-and-consumption/bunker-consumption/bunker-consumption.component";
import { CpSpeedComponent } from "./speed-and-consumption-tab/cp-speed-and-consumption/cp-speed/cp-speed.component";
import { CpSpeedAndConsumptionComponent } from "./speed-and-consumption-tab/cp-speed-and-consumption/cp-speed-and-consumption.component";
import { EngineConsumptionComponent } from "./speed-and-consumption-tab/cp-speed-and-consumption/engine-consumption/engine-consumption.component";
import { SpeedAndConsumptionTabComponent } from "./speed-and-consumption-tab/speed-and-consumption-tab.component";
import { AtSeaBunkerConsumedComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/at-sea-bunker-consumed/at-sea-bunker-consumed.component";
import { AtSeaConsumptionComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/at-sea-consumption/at-sea-consumption.component";
import { VoyageBunkerConsumptionActivityComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/voyage-bunker-consumption-activity/voyage-bunker-consumption-activity.component";
import { VoyageBunkerConsumptionLocationComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/voyage-bunker-consumption-location/voyage-bunker-consumption-location.component";
import { VoyageBunkerConsumptionComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/voyage-bunker-consumption.component";
import { VoyageBunkersRemainingComponent } from "./speed-and-consumption-tab/voyage-bunker-consumption/voyage-bunkers-remaining/voyage-bunkers-remaining.component";
import { FixtureStoreModule } from "./state";
import { SummaryComponent } from "./summary/summary.component";
import { BunkersComponent } from "./time-charter/hire-tab/bunkers/bunkers.component";
import { DeliveryComponent } from "./time-charter/hire-tab/delivery/delivery.component";
import { NoticesComponent } from "./time-charter/hire-tab/notices/notices.component";
import { OffHireComponent } from "./time-charter/hire-tab/offhire/offhire.component";
import { OptionsComponent } from "./time-charter/hire-tab/options/options.component";
import { PeriodsComponent } from "./time-charter/hire-tab/periods/periods.component";
import { RedeliveryComponent } from "./time-charter/hire-tab/redelivery/redelivery.component";
import { HireInvoicesComponent } from "./time-charter/invoices-tab/hire-invoices/hire-invoices.component";
import { TimeCharterHeadingComponent } from "./time-charter/time-charter-heading/time-charter-heading.component";
import { TimeCharterComponent } from "./time-charter/time-charter.component";
import { VesselNominationHistoryTableComponent } from "./vessel-nomination-history/vessel-nomination-history-table.component";
import { FreightInvoicesComponent } from "./voyage/invoices-tab/freight-invoices/freight-invoices.component";
import { VoyageHeadingComponent } from "./voyage/voyage-heading/voyage-heading.component";
import { VoyageComponent } from "./voyage/voyage.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        NgrxFormsModule,
        FixtureRoutingModule,
        FixtureStoreModule,
        FixtureWarningsModule,
        NgbModalModule,
        NgbNavModule,
        NgxFileDropModule,
        MatDialogModule,
        TableModule,
        ToggleButtonModule,
        TabMenuModule,
        TabViewModule
    ],
    declarations: [
        FixtureComponent,
        SummaryComponent,
        TimeCharterHeadingComponent,
        VoyageHeadingComponent,
        DeliveryComponent,
        VoyageComponent,
        TimeCharterComponent,
        PeriodsComponent,
        ExpenseTabComponent,
        ExpenseComponent,
        ExpenseSummaryComponent,
        OptionsComponent,
        OffHireComponent,
        DemurrageTabComponent,
        TimebarsComponent,
        ClaimsNotificationComponent,
        DemurrageClaimContainerComponent,
        DemurrageClaimComponent,
        RedeliveryComponent,
        BunkersComponent,
        NoticesComponent,
        HireInvoicesComponent,
        CommissionComponent,
        FreightInvoicesComponent,
        InvoiceSummaryComponent,
        DemurrageClaimSummaryComponent,
        CpSpeedAndConsumptionComponent,
        SpeedAndConsumptionTabComponent,
        CpSpeedComponent,
        BunkerConsumptionComponent,
        EngineConsumptionComponent,
        VoyageBunkerConsumptionComponent,
        AtSeaConsumptionComponent,
        AtSeaBunkerConsumedComponent,
        VoyageBunkerConsumptionLocationComponent,
        VoyageBunkerConsumptionActivityComponent,
        VoyageBunkersRemainingComponent,
        LaytimeTabComponent,
        LaytimeContainerComponent,
        CargoAllowedRatesComponent,
        LaytimeEventFactsComponent,
        StatementOfFactsContainerComponent,
        BerthStatementOfFactsComponent,
        AssociatedCargoesStatementOfFactsComponent,
        LaytimeSummaryComponent,
        DivisionsDirective,
        FixtureSourceDirective,
        FixtureTypeDirective,
        LaytimeCalculationComponent,
        LocationAutosuggestComponent,
        CargoAutosuggestComponent,

        // NGRX NEW
        VoyageShellComponent,
        TimeCharterVoyageFormComponent,

        CargoesComponent,
        CargoFormComponent,
        SpecialisedCargoFormComponent,
        SpecialisedCargoShellComponent,

        DestinationsHeaderComponent,
        DestinationsComponent,
        DestinationShellComponent,
        DestinationHeaderComponent,
        DestinationFormComponent,

        BerthsComponent,
        BerthShellComponent,
        BerthHeaderComponent,
        BerthFormComponent,

        ActivitiesComponent,
        ActivityShellComponent,
        ActivityHeaderComponent,
        ActivityFormComponent,

        LaytimeEventsComponent,

        AssociatedCargoesComponent,
        AssociatedCargoExpensesComponent,

        SectionHeaderComponent,
        TotalsFormComponent,
        PublicNotesFormComponent,
        VoyageCostBreakdownComponent,

        // P&L
        ProfitAndLossShellComponent,
        ProfitAndLossComponent,

        // Fixture Popup
        FixturePopupCommentsComponent,
        FixturePopupLaytimeEventsComponent,
        FixturePopupFixtureInfoComponent,
        FixturePopupBodyComponent,
        FixturePopupHeaderComponent,
        FixturePopupFooterComponent,
        FixturePopupTabHeaderComponent,
        FixturePopupDestinationTabHeaderComponent,
        FixturePopupDestinationTabHeaderFormComponent,
        FixturePopupActivityTabHeaderComponent,
        FixturePopupActivityTabHeaderFormComponent,

        // Vessel Renomination
        VesselNominationHistoryTableComponent
    ],
    providers: [
        VoyageDataService,
        { provide: FIXTURE_POPUP_TAB_CREATOR, useClass: FixturePopupCommentsTabCreator, multi: true },
        { provide: FIXTURE_POPUP_TAB_CREATOR, useClass: FixturePopupActivitiesTabCreator, multi: true },
        { provide: FIXTURE_POPUP_TAB_CREATOR, useClass: FixturePopupFixtureInfoTabCreator, multi: true }
    ],
    exports: [
        LocationAutosuggestComponent,
        CargoAutosuggestComponent,
        FixturePopupBodyComponent,
        FixturePopupHeaderComponent,
        FixturePopupFooterComponent,
        VesselNominationHistoryTableComponent
    ]
})
export class FixtureModule {}
