import { createFormGroupState, FormArrayState, FormGroupState } from "ngrx-forms";

import { FreightType } from "@ops/shared/reference-data";
import { AppState, LoadStatus, PersistenceStatus, StateObjectMap } from "@ops/state";

import { defaultCargoesExpanded, defaultDestinationsExpanded, VoyageExpandedSections } from "./expanded-sections";
import { FixtureId } from "./fixture";
import { VoyageForm, VoyageId, voyageToForm } from "./voyage";
import { FixtureStatusType } from "../../../left-bar/shared/models";
import { Nullable } from "../../../shared";
import { Division, Fixture, FixtureSource, FixtureType, Voyage } from "../../shared/models";
import { FixtureTabsValidationInfo } from "../../shared/tab-validation/tab-validation-info";
import { DismissedWarningRecord } from "../../shared/warnings/fixture-warning.model";
import { VesselNominationForm } from "../../vessel-nomination-history/vessel-nomination-form";

/**
 * @experimental Incomplete, grid state should be same across girds
 */
export declare type FixtureGridState = Readonly<{
    // grid: {
    //     criteria: SearchCriteria[];
    //     suggestions: {
    //       items: readonly SearchCriteria[];
    //       state: 'loading' | 'errored';
    //       error: Error | string;
    //     };
    //     fixtures: {
    //       items: FixtureGridRow[];
    //       state: 'loading' | 'errored';
    //       error: Error | string;;
    //       offset: number;
    //       count: number;
    //       total: number;
    //     };
    //   };
}>;

export declare type FormExpandedStatus = {
    [id: string]: boolean;
};

export declare type VoyageState = Readonly<{
    voyage?: Voyage;
    workingVoyage?: Voyage; // Interop voyage, set by VoyageDataService (latest form values)
    form: FormGroupState<VoyageForm>;
    loadStatus?: LoadStatus;
    persistenceStatus?: PersistenceStatus;
    expandedSections?: VoyageExpandedSections;
}>;

export declare type FixtureState = Readonly<{
    fixture?: Fixture;
    workingFixture?: Fixture; // Interop fixture, set by FixtureDataService (latest form values)
    dismissedWarnings?: ReadonlyArray<DismissedWarningRecord>;
    vesselNominationForms?: FormArrayState<VesselNominationForm>;
    loadStatus?: LoadStatus;
    persistenceStatus?: PersistenceStatus;
    voyageIds?: ReadonlyArray<VoyageId>;
    voyagesLoadStatus?: LoadStatus;
    voyageCreationPending?: boolean;
    validationByTabs?: FixtureTabsValidationInfo;
}>;

export declare type FixturesState = Readonly<{
    // grid: FixtureGridState;
    fixtures: StateObjectMap<FixtureState>;
    voyages: StateObjectMap<VoyageState>;
    /**
     * The identifier of the current Fixture.
     * DO NOT SET this - it is set by a router action.
     */
    currentFixtureId?: FixtureId;
    /**
     * The identifier of the current Voyage.
     * DO NOT SET this - it is set by a router action.
     */
    currentVoyageId?: VoyageId;
}>;

export type FixtureFeatureState = AppState &
    Readonly<{
        fixtures: FixturesState;
    }>;

export const getCurrentFixtureId = (state: FixturesState) => state.currentFixtureId;
export const getCurrentVoyageId = (state: FixturesState) => state.currentVoyageId;

export const getFixtureState = (state: FixturesState, fixtureId: FixtureId) => state.fixtures.byId[fixtureId];
export const getVoyageState = (state: FixturesState, voyageId: VoyageId) => state.voyages.byId[voyageId];
export const getCurrentFixtureState = (state: FixturesState) => state.fixtures.byId[getCurrentFixtureId(state)];
export const getCurrentVoyageState = (state: FixturesState): Nullable<VoyageState> => state.voyages.byId[getCurrentVoyageId(state)];

export const getFixtureType = (state: FixtureState): FixtureType => state.fixture.fixtureType.id;
export const getFixtureStatus = (state: FixtureState): FixtureStatusType => (state.workingFixture || state.fixture).fixtureStatus.id;
export const getDivision = (state: FixtureState): Division => state.fixture.division.id;

export const isFixtureType = (state: FixtureState, type: FixtureType) => getFixtureType(state) === type;
export const isDivision = (state: FixtureState, division: Division) => getDivision(state) === division;
export const isFixtureStatus = (state: FixtureState, status: FixtureStatusType) => getFixtureStatus(state) === status;
export const isFixtureSource = (state: FixtureState, source: FixtureSource) => state.fixture.fixtureSource.id === source;
export const isFixtureFreightType = (state: FixtureState, type: FreightType) => {
    const fixture = state.workingFixture || state.fixture;

    return fixture.freightType && fixture.freightType.id === type.id;
};

export const initialVoyageExpandedSections = (): VoyageExpandedSections => ({
    cargoes: true,
    destinations: true,
    notes: false,
    totals: true,
    costs: true
});

export const getVoyageSectionExpanded = (state: FixturesState, voyageId: VoyageId, key: keyof VoyageExpandedSections) => !!getVoyageState(state, voyageId).expandedSections[key];

export const getCurrentVoyageSectionExpanded = (state: FixturesState, key: keyof VoyageExpandedSections) => getVoyageSectionExpanded(state, getCurrentVoyageId(state), key);

export const initialVoyageState = (voyage: Voyage, fixtureType: FixtureType, division: Division): VoyageState => {
    const form = voyageToForm(voyage, fixtureType, division);
    const voyageFormState = createFormGroupState(voyage.voyageId, form);

    return {
        voyage,
        form: voyageFormState,
        loadStatus: "loaded",
        expandedSections: {
            ...initialVoyageExpandedSections(),
            ...defaultCargoesExpanded(division, form.cargoes),
            ...defaultDestinationsExpanded(division, form.destinations)
        }
    };
};
